import {forwardRef, useRef, useState} from 'react';
import {AppBar, Dialog, Divider, IconButton, List, Slide, Toolbar, Typography} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import BackIcon from '@mui/icons-material/ArrowBackIosNew';
import ChatListItem from "./ChatListItem";

const chats = [
    {
        name: "Harley Scott",
        message: "I'll be in your neighborhood doing errands this…I'll be in your neighborhood doing errands this…I'll be in your neighborhood doing errands this…I'll be in your neighborhood doing errands this…I'll be in your neighborhood doing errands this…I'll be in your neighborhood doing errands this…",
        group: true
    },
    {
        name: "Clyde King",
        message: "I'll be in your neighborhood doing errands this…",
        group: false
    },
    {
        name: "Marion Jackson",
        message: "I'll be in your neighborhood doing errands this…",
        group: false
    },
    {
        name: "Damian Noble",
        message: "I'll be in your neighborhood doing errands this…",
        group: false
    },
    {
        name: "Camilla Gomez",
        message: "I'll be in your neighborhood doing errands this…",
        group: false
    },
    {
        name: "Brianna Cohen",
        message: "I'll be in your neighborhood doing errands this…",
        group: false
    },
    {
        name: "Summer BBQ", message: "Indescribable oppression, which seemed to generate in some unfamiliar part of her consciousness, filled her whole being with a vague anguish.", group: true
    },
    {
        name: "Clark Family", message: "It all started with the computer. Had he known what was to follow, he would have never logged on that day. ", group: true
    }
];

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenChat(props) {
    const {open, handleClose} = props;

    const [checked, setChecked] = useState(false);
    const [chatTitle, setChatTitle] = useState("Chat");

    const containerRef = useRef(null);

    const handleChange = event => {
        setChecked(false);
        setChatTitle("Chat")
    };

    const openConversation = (title) => {
        setChecked(true)
        setChatTitle(title)
    }

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
            ref={containerRef}
        >
            <AppBar sx={{position: 'relative'}}>
                <Toolbar>
                    {checked &&
                        <IconButton color="inherit" aria-label="back" onClick={handleChange}>
                            <BackIcon/>
                        </IconButton>
                    }

                    <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
                        {chatTitle}
                    </Typography>
                    <IconButton autoFocus color="inherit" aria-label="delete" onClick={handleClose}>
                        <CloseIcon/>
                    </IconButton>
                </Toolbar>
            </AppBar>
            {!checked ?
                <List sx={{width: '100%', bgcolor: 'background.paper'}}>
                    {chats.map((chat, i) => (
                        <>
                            <ChatListItem key={chat.name + '-chatl'}
                                          chatName={chat.name}
                                          message={chat.message}
                                          isGroup={chat.group}
                                          openChat={openConversation}
                            />
                            <Divider variant="inset" component="li"/>
                        </>
                    ))}
                </List>
                :
                <Slide direction="left" in={checked} container={containerRef.current} mountOnEnter unmountOnExit>
                    <List sx={{width: '100%', bgcolor: 'background.paper'}}>
                        {chats.map((chat, i) => (
                            <>
                                <ChatListItem key={chat.name + '-chat'}
                                              chatName={chat.name}
                                              message={chat.message}
                                              group={chat.group}/>
                                <Divider variant="inset" component="li"/>
                            </>
                        ))}
                    </List>
                </Slide>
            }
        </Dialog>
    );
}