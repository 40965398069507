import MapView from "../components/Map";
import BasicSpeedDial from "../components/BasicSpeedDial";
import {useState} from "react";
import FullScreenChat from "../components/FullScreenChat";

export default function Home() {
    const [chatOpen, setChatOpen] = useState(false)
    const [speedDialOpen, setSpeedDialOpen] = useState(false)
    const [exploring, setExploring] = useState(false)
    const toggleSpeedDial = () => {
        setSpeedDialOpen(current => !current);
    }
    const onExploreClick = () => {
        setExploring(current => !current);
    }
    const handleChatOpen = () => {
        setChatOpen(true);
    };
    const handleChatClose = () => {
        setChatOpen(false);
    };

    return (
        <>
            <MapView exploring={exploring}/>
            <BasicSpeedDial open={speedDialOpen} toggleOpen={toggleSpeedDial} onExploreClick={onExploreClick}
                            onChatClick={handleChatOpen}/>
            <FullScreenChat open={chatOpen} handleClose={handleChatClose}/>
        </>
    )
}