import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import SettingsIcon from '@mui/icons-material/Settings';
import AllianceIcon from '@mui/icons-material/LocalPolice';
import ChatIcon from '@mui/icons-material/Chat';
import ExploreIcon from '@mui/icons-material/DirectionsWalk';
import FriendsIcon from '@mui/icons-material/Group';

export default function BasicSpeedDial(props) {
    const {open, toggleOpen, onExploreClick, onChatClick} = props;

    const actions = [
        {
            icon: <SettingsIcon/>, name: 'Settings', onClick: () => {
            }
        },
        {
            icon: <FriendsIcon/>, name: 'Friends', onClick: () => {
            }
        },
        {icon: <AllianceIcon/>, name: 'Alliance', onClick: () => {}},
        {icon: <ChatIcon/>, name: 'Chat', onClick: onChatClick},
        {icon: <ExploreIcon/>, name: 'Explore', onClick: onExploreClick},
    ];

    return (
        <SpeedDial
            ariaLabel="SpeedDial basic example"
            sx={{position: 'absolute', bottom: 16, right: 16}}
            icon={<SpeedDialIcon/>}
            open={open}
            onClick={toggleOpen}
        >
            {actions.map((action) => (
                <SpeedDialAction
                    key={action.name}
                    icon={action.icon}
                    tooltipTitle={action.name}
                    tooltipOpen
                    onClick={action.onClick}
                />
            ))}
        </SpeedDial>
    );
}