import NavigationIcon from "@mui/icons-material/Navigation";
import {Marker} from "react-map-gl";

export default function PositionMarker(props) {
    const {latitude, longitude} = props;

    return (
        <Marker
            longitude={longitude}
            latitude={latitude}
            anchor={"center"}
        >
            <NavigationIcon
                fontSize={'large'}
                color={'primary'}
            />
        </Marker>
    )
}
