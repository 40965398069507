import {Avatar, Box, ListItemAvatar, ListItemButton, ListItemText, Typography} from "@mui/material";

export default function ChatListItem(props) {
    const {key, chatName, message, isGroup, openChat} = props;
    const PersonalChat = () => {
        return (
            <Typography
                sx={{
                    // display: 'inline',
                    wordBreak: "break-word",
                    whiteSpace: "pre-line",
                    display: '-webkit-box',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    WebkitLineClamp: '2',
                    WebkitBoxOrient: 'vertical',
                }}
                component="span"
                variant="body2"
                noWrap
            >
                {message}
            </Typography>
        )
    }
    const GroupChat = () => {
        return (
            <>
                <Typography
                    sx={{
                        display: 'inline',
                        // display: '-webkit-box, inline',
                        // overflow: 'hidden',
                        // textOverflow: 'ellipsis',
                        // WebkitLineClamp: '2',
                        // WebkitBoxOrient: 'vertical',
                    }}
                    component="div"
                    variant="body2"
                    color="text.primary"
                    noWrap
                >
                    <Box fontWeight='fontWeightMedium' display='inline'>
                        {"Scott: "}
                    </Box>
                    {/*{message}*/}
                    <PersonalChat/>
                </Typography>


            </>
        )
    }
    return (
        <ListItemButton key={key} alignItems="flex-start" onClick={() => {
            openChat(chatName)
        }}>
            <ListItemAvatar>
                <Avatar alt={chatName} src="/static/images/avatar/1.jpg"/>
            </ListItemAvatar>
            <ListItemText
                primary={chatName}
                secondary={isGroup ? <GroupChat/> : <PersonalChat/>}
            />
        </ListItemButton>
    )
}