import {useEffect, useState} from "react";
import useArray from "./useArray";
import turf from 'turf'

function getDistanceFromLatLonInKilometers(lat1, lon1, lat2, lon2) {
    return turf.distance([lon1, lat1],[lon2, lat2]);
}
function getDistanceFromLatLonInMeters(lat1, lon1, lat2, lon2) {
    return getDistanceFromLatLonInKilometers(lat1, lon1, lat2, lon2) * 1000;
}

export default function useRouteTrack() {
    const [coords, setCoords] = useState([])

    const {
        array: track,
        push: pushTrack,
        clear: clearTrack
    } = useArray([])

    function add(timestamp, lat, long, accuracy) {
        pushTrack({
            timestamp: timestamp,
            latitude: lat,
            longitude: long,
            accuracy: accuracy
        })
    }

    function reset() {
        clearTrack();
    }

    useEffect(() => {
        let c = []
        if (track.length > 0) {
            let previousPoint = [track[0].longitude, track[0].latitude];
            let previousAccuracy = track[0].accuracy;
            c.push(previousPoint);

            track.forEach((point,i) => {
                const distance = getDistanceFromLatLonInMeters(previousPoint[1], previousPoint[0], point.latitude, point.longitude)

                if (distance > previousAccuracy) {
                    c.push([point.longitude, point.latitude])
                    previousPoint = [point.longitude, point.latitude]
                    previousAccuracy = point.accuracy
                } else if (previousAccuracy > point.accuracy) {
                    c.pop()
                    c.push([point.longitude, point.latitude])
                    previousPoint = [point.longitude, point.latitude]
                    previousAccuracy = point.accuracy
                }

                if (i === track.length-1) {  // TODO check this is most accurate?
                    c.pop()
                    c.push([point.longitude, point.latitude])
                }
            })
        }
        setCoords(c)
    }, [track])

    return {track, add, reset, coords}
}
