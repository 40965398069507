import {useEffect, useRef} from "react";

// Runs useEffect only on update. Does not run on mount.
export default function useUpdateEffect(callback, dependencies) {
    const firstRenderRef = useRef(true)

    useEffect(() => {
        if (firstRenderRef.current) {
            firstRenderRef.current = false;
            return
        }
        return callback()
    }, dependencies)
}

// Example
// -----------
// useUpdateEffect(() => {
//     alert(count)
// }, [count])