import {Layer, Source} from "react-map-gl";
import {highlightLayer, settlementsLayer} from "./map-style";
import {useEffect, useMemo, useState} from "react";
import {cellToBoundary, polygonToCells} from "h3-js";

const POLYGON_RESOLUTION = 8;

export default function H3HexSource({mapBounds, selected}) {
    const [features, setFeatures] = useState([]);

    function createHexPolys(bounds) {
        const {minX, maxX, minY, maxY} = bounds;
        const width = maxX - minX;
        const height = maxY - minY;
        const miX = minX - width / 2;
        const maX = maxX + width / 2;
        const miY = minY - height / 2;
        const maY = maxY + height / 2;
        const mask = [
            [maY, miX],
            [maY, maX],
            [miY, maX],
            [miY, miX],
            [maY, miX]
        ];

        let polys = {};
        polygonToCells(mask, POLYGON_RESOLUTION, true).forEach(cell => {
            polys[cell] = cellToBoundary(cell);
        });

        let mfeatures = [];
        Object.entries(polys).forEach(p => {
            const [key, value] = p;
            mfeatures.push({
                type: 'Feature',
                properties: {
                    name: key
                },
                geometry: {
                    type: 'Polygon',
                    coordinates: [value]
                }
            })
        })
        setFeatures(mfeatures);
    }

    useEffect(() => {
        createHexPolys((mapBounds));
    }, [mapBounds])

    const filter = useMemo(() => ['in', 'name', selected], [selected]);

    return (
        <Source type="geojson" data={{
            type: 'FeatureCollection',
            features: features
        }}>
            <Layer {...settlementsLayer} />
            <Layer {...highlightLayer} filter={filter}/>
        </Source>
    )
}