import Home from "./pages/Home";
import Navbar from "./layouts/Navbar";
import {Box} from "@mui/material";

import useClientRect from "./hooks/useClientRect";

function App() {
    const [navbarRect, navbarRef] = useClientRect();

    const navbarHeight = navbarRect ? navbarRect.height : 0;

    return (
        <div className="App">
            <Navbar navbarRef={navbarRef}/>
            <Box component="main" sx={{height: `calc(100vh - ${navbarHeight}px - env(safe-area-inset-bottom) - env(safe-area-inset-top))`, width: '100%'}}>
                <Home navbarPadding={navbarRef}/>
            </Box>
        </div>
    );
}

export default App;
