import React, {useEffect, useRef, useState} from 'react';
import {useGeolocated} from "react-geolocated";
import Map from 'react-map-gl';
import {Box, CircularProgress, Typography} from "@mui/material";
import {cellToLatLng} from "h3-js";
import 'mapbox-gl/dist/mapbox-gl.css';
import PositionMarker from "./PositionMarker";
import useRouteTrack from "../hooks/useRouteTrack";
import useUpdateEffect from "../hooks/useUpdateEffect";
import turf from "turf";
import TrackSource from "./TrackSource";
import H3HexSource from "./H3HexSource";

const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
const VIEW_RESET_TIMEOUT = 10000;
const isDebug = false;

const ZOOM_LEVEL = 12;
const DETAIL_PITCH = 60
export default function MapView({exploring}) {
    const mapRef = useRef(null);
    const viewResetTimer = useRef(null);
    const [zoom, setZoom] = useState(14);

    const [mapBounds, setMapBounds] = useState({minX: 0, maxX: 0, minY: 0, maxY: 0})
    const [hoverInfo, setHoverInfo] = useState(null)
    const [mapDragging, setMapDragging] = useState(false) // TODO Might want to change to interacting instead.

    //const [heading, setHeading] = useState(0)

    const {add: addToTrack, reset: resetTrack, coords: trackCoords} = useRouteTrack()
    const {coords, timestamp: coordsTimestamp, isGeolocationAvailable, isGeolocationEnabled} = useGeolocated({
        positionOptions: {
            enableHighAccuracy: true,
        },
        userDecisionTimeout: 5000,
        watchPosition: true,
    });

    //region Map Functions
    const resetView = () => {
        mapRef.current.flyTo({
            center: [coords.longitude, coords.latitude],
            zoom: zoom,
        });
    }
    const updateMapBounds = () => {
        const [[minX, minY], [maxX, maxY]] = mapRef.current.getBounds().toArray();
        setMapBounds({
            minX: minX,
            maxX: maxX,
            minY: minY,
            maxY: maxY
        });
    }
    //endregion Map Functions

    //region Events
    const handleOnLoad = event => {
        updateMapBounds();
    }
    const handleOnMove = event => {
        updateMapBounds();
    }
    const handleOnDragStart = event => {
        setMapDragging(true);
    }
    const handleOnDragEnd = event => {
        setMapDragging(false);
    }
    const handleOnZoomEnd = event => {
        if (mapRef.current === null) return
        let pitch = (zoom > ZOOM_LEVEL) ? DETAIL_PITCH : 0;
        let currentPitch = mapRef.current.getMap().transform.pitch;

        if (pitch !== currentPitch) {
            mapRef.current.easeTo({
                pitch: pitch, duration: 300, easing: time => {
                    return time * (2 - time)
                }
            })
        }

    }
    const handleOnZoom = event => {
        setZoom(event.viewState.zoom);
    }
    const handleOnClick = event => {
        const feature = event.features[0];
        if (feature) {
            const cellId = feature.properties.name;
            const [lng, lat] = cellToLatLng(cellId)
            mapRef.current.flyTo({
                center: [lng, lat],
                zoom: 15,
            });
        }
    }
    const onHover = event => {
        const settlementFeature = event.features && event.features[0];
        setHoverInfo({
            longitude: event.lngLat.lng,
            latitude: event.lngLat.lat,
            settlementName: settlementFeature && settlementFeature.properties.name
        });
    };
    //endregion Events

    const selectedSettlement = (hoverInfo && hoverInfo.settlementName) || '';

    useEffect(() => {
        if (exploring === false) {
            resetTrack()
            if (mapRef.current !== null) {
                mapRef.current.easeTo({
                    center: [coords.longitude, coords.latitude],
                    bearing: 0,
                    duration: 300, easing: time => {
                        return time * (2 - time)
                    }
                })
            }
        }
    }, [exploring])

    useEffect(() => {
        if (!mapDragging) {
            viewResetTimer.current = setTimeout(resetView, VIEW_RESET_TIMEOUT);
        }

        return () => {
            clearInterval(viewResetTimer.current);
        };
    }, [mapDragging])

    useEffect(() => {
        if (!coords) return;
        if (mapRef.current === null) return;

        if (exploring) {
            addToTrack(coordsTimestamp, coords.latitude, coords.longitude, coords.accuracy)
        }

    }, [coords])

    useUpdateEffect(() => {
        if (trackCoords.length >= 2) {
            let bearing = turf.bearing(trackCoords.at(-2), trackCoords.at(-1))
            mapRef.current.easeTo({
                center: trackCoords.at(-1),
                bearing: bearing,
                duration: 300,
                easing: time => {
                    return time * (2 - time)
                }
            })
        }
    }, [trackCoords])

    return (
        <Box sx={{height: '100%'}}>
            {isDebug &&
                <Typography> Heading: {coords.heading} Speed: {coords.speed} Accuracy: {coords.accuracy} </Typography>}
            {!isGeolocationEnabled && <Typography>You need to enable Geolocation for this app to work!</Typography>}
            {!isGeolocationAvailable ? (
                <div>Your browser does not support Geolocation</div>
            ) : !isGeolocationEnabled ? (
                <div>Geolocation is not enabled</div>
            ) : coords ?
                <>
                    <Map
                        ref={mapRef}
                        mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
                        initialViewState={{
                            longitude: coords.longitude,
                            latitude: coords.latitude,
                            zoom: zoom,
                            pitch: DETAIL_PITCH
                        }}
                        style={{
                            height: '100%'
                        }}
                        mapStyle="mapbox://styles/rich1992/classxcrx002t14nkq6xcaoa8"
                        onLoad={handleOnLoad}
                        onClick={handleOnClick}
                        onMove={handleOnMove}
                        onDragStart={handleOnDragStart}
                        onDragEnd={handleOnDragEnd}
                        onZoomEnd={handleOnZoomEnd}
                        onZoom={handleOnZoom}
                        interactiveLayerIds={['settlements']}
                        onMouseMove={onHover}
                        touchPitch={false}
                    >
                        <>
                            {zoom > ZOOM_LEVEL &&
                                <>
                                    <H3HexSource mapBounds={mapBounds} selected={selectedSettlement}/>
                                    {exploring &&
                                        <PositionMarker longitude={coords.longitude} latitude={coords.latitude}/>
                                    }
                                </>
                            }
                            <TrackSource trackCoords={trackCoords}/>
                        </>
                    </Map>
                    {/*<ControlPanel heading={heading} speed={speed} accuracy={accuracy}/>*/}
                </>
                : <CircularProgress/>
            }
        </Box>
    );
}