import {useCallback, useState} from "react";

export default function useArray(defaultValue = []) {
    const [array, setArray] = useState(defaultValue)

    const push = useCallback(element => {
        // console.log(element)
        // console.log(array)
        setArray(a => [...a, element])
    }, [])

    const filter = useCallback(callback => {
        setArray(a => a.filter(callback))
    }, [])

    const update = useCallback( (index, newElement)  => {
        setArray(a => [
            ...a.slice(0, index),
            newElement,
            ...a.slice(index + 1, a.length - 1),
        ])
    }, [])

    const remove = useCallback(index => {
        setArray(a => [...a.slice(0, index), ...a.slice(index + 1, a.length - 1)])
    }, [])

    const clear = useCallback(() => {
        setArray([])
    }, [])

    return {array, set: setArray, push, filter, update, remove, clear}
}

// const {array, set, push, remove, filter, update, clear} = useArray([
//     1, 2, 3, 4, 5, 6,
// ])