export const settlementsLayer = {
    id: 'settlements',
    type: 'fill',
    // 'source-layer': 'original',
    paint: {
        'fill-outline-color': 'rgba(0,0,0,1)',
        'fill-color': 'rgba(0,0,0,0.1)'
    }
};
// Highlighted settlements polygons
export const highlightLayer = {
    id: 'settlements-highlighted',
    type: 'fill',
    // source: 'settlements',
    // 'source-layer': 'original',
    paint: {
        'fill-outline-color': '#484896',
        'fill-color': '#6e599f',
        'fill-opacity': 0.75
    },
    transition: {

    }
};

export const trackLayer = {
    id: 'route-track',
    type: 'line',
    // source: 'settlements',
    // 'source-layer': 'original',
    paint: {
        // 'line-color': '#484896',
        'line-color': 'red',
        'line-width': 5,
        'line-opacity': 0.75
    },
};