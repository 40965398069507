import {Layer, Source} from "react-map-gl";
import {trackLayer} from "./map-style";
import React from "react";

export default function TrackSource(props) {
    const {trackCoords} = props;
    return (
        <Source type="geojson" data={{
            "type": "FeatureCollection",
            "features": [{
                type: "Feature",
                properties: {},
                geometry: {
                    type: "LineString",
                    coordinates: trackCoords,
                }
            }]
        }}>
            <Layer {...trackLayer} />
        </Source>
    )
}
